import { Button, message, Drawer, notification, ConfigProvider, Typography, TablePaginationConfig } from 'antd';
import type { ProDescriptionsItemProps } from '@ant-design/pro-descriptions';
import ProDescriptions from '@ant-design/pro-descriptions';
import type { ProColumns } from '@ant-design/pro-table';
import { useIntl, FormattedMessage } from '@umijs/max';
import enUS from 'antd/es/locale/en_US';
import defaultSettings from '../config/defaultSettings';
import GetOidcSettings from '../config/others/oidcSettings';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

export const keyAccessToken = 'access_token';

const { pwa } = defaultSettings;
const isHttps = document.location.protocol === 'https:';

export const appTitle = defaultSettings.title;

const clearCache = () => {
  // remove all caches
  if (window.caches) {
    caches
      .keys()
      .then((keys) => {
        keys.forEach((key) => {
          caches.delete(key);
        });
      })
      .catch((e) => console.log(e));
  }
};

export function capitalizeFirstLetter(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function renderDetails<Type extends Record<string, any>>(
  showDetail: boolean,
  title: string | undefined,
  currentRow: Type | undefined,
  columns: ProColumns<Type>[],
  setCurrentRow: React.Dispatch<React.SetStateAction<Type | undefined>>,
  setShowDetail: React.Dispatch<React.SetStateAction<boolean>>,
) {
  if (!showDetail) return null;
  return (
    (<Drawer
      width={600}
      open={true}
      onClose={() => {
        setCurrentRow(undefined);
        setShowDetail(false);
      }}
      closable={false}
    >
      <ProDescriptions<Type>
        column={1}
        title={<Typography.Text copyable code={true}>{title}</Typography.Text>}
        request={async () => ({
          data: currentRow || {},
        })}
        columns={columns as ProDescriptionsItemProps<Type>[]}
      />
    </Drawer>)
  );
}

export const t = (id: string, plain: boolean = false, defaultT = '') =>
  plain ? (
    useIntl().formatMessage({ id, defaultMessage: defaultT || id })
  ) : (
    <FormattedMessage id={id} defaultMessage={defaultT || id} />
  );

// if pwa is true
if (pwa) {
  // Notify user if offline now
  window.addEventListener('sw.offline', () => {
    message.warning(useIntl().formatMessage({ id: 'app.pwa.offline' }));
  });

  // Pop up a prompt on the page asking the user if they want to use the latest version
  window.addEventListener('sw.updated', (event: Event) => {
    const e = event as CustomEvent;
    const reloadSW = async () => {
      // Check if there is sw whose state is waiting in ServiceWorkerRegistration
      // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration
      const worker = e.detail && e.detail.waiting;
      if (!worker) {
        return true;
      }
      // Send skip-waiting event to waiting SW with MessageChannel
      await new Promise((resolve, reject) => {
        const channel = new MessageChannel();
        channel.port1.onmessage = (msgEvent) => {
          if (msgEvent.data.error) {
            reject(msgEvent.data.error);
          } else {
            resolve(msgEvent.data);
          }
        };
        worker.postMessage({ type: 'skip-waiting' }, [channel.port2]);
      });

      clearCache();
      window.location.reload();
      return true;
    };
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        onClick={() => {
          notification.destroy(key);
          reloadSW();
        }}
      >
        {useIntl().formatMessage({ id: 'app.pwa.serviceworker.updated.ok' })}
      </Button>
    );
    notification.open({
      message: useIntl().formatMessage({ id: 'app.pwa.serviceworker.updated' }),
      description: useIntl().formatMessage({ id: 'app.pwa.serviceworker.updated.hint' }),
      btn,
      key,
      onClose: async () => null,
    });
  });
} else if ('serviceWorker' in navigator && isHttps) {
  // unregister service worker
  const { serviceWorker } = navigator;
  if (serviceWorker.getRegistrations) {
    serviceWorker.getRegistrations().then((sws) => {
      sws.forEach((sw) => {
        sw.unregister();
      });
    });
  }
  serviceWorker.getRegistration().then((sw) => {
    if (sw) sw.unregister();
  });

  clearCache();
}

export const delay = (time: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

export const csvFormat = (s: string) => {
  let s2 = s.replace(/"/g, '""');
  if (s2.indexOf(',') > -1 || s2.indexOf('\n') > -1 || s2.indexOf('\r') > -1) {
    s2 = `"${s2}"`;
  }
  return s2;
};

export const SaveCsv = (titles: string[], rows: string[][], filename: string) => {
  let s = '';
  s += titles.join(',') + '\n';
  for (let i = 0; i < rows.length; i++) {
    s += rows[i].join(',') + '\n';
  }
  const blob = new Blob([s]);
  const a = window.document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = `${filename}.csv`;
  a.setAttribute('visibility', 'hidden');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export function formatYMDFromTimestamp(timestampSec: number): string {
  const date = new Date(timestampSec * 1000);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
}

export function formatYMDHMFromTimestamp(timestampSec: number): string {
  const date = new Date(timestampSec * 1000);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export const fallbackEnUs = (children: React.ReactNode) => {
  return <ConfigProvider locale={enUS}>{children}</ConfigProvider>;
};

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load();
var visitorId = '';
(async () => {
  // Get the visitor identifier when you need it.
  const fp = await fpPromise;
  const result = await fp.get();
  visitorId = result.visitorId;
})();

export const getFingerprint = () => {
  return visitorId;
};

export const get_os_svg = (size: string, os: string) => {
  const winSvg = () => (
    <svg viewBox="0 0 448 512" width={size} height={size} fill="#0078D7">
      <path d="m0 93.7 183.6-25.3v177.4H0V93.7zm0 324.6 183.6 25.3V268.4H0v149.9zm203.8 28L448 480V268.4H203.8v177.9zm0-380.6v180.1H448V32L203.8 65.7z" />
    </svg>
  );
  const WinIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={winSvg} {...props} />
  );
  const linuxSvg = () => (
    <svg viewBox="0 0 448 512" width={size} height={size} fill="#007CFF">
      <path fill-rule="evenodd" d="M220.499 479.926c-74.052 0-136.663-53.729-147.853-118.13-.591-3.404-3.789-5.476-7.137-4.626l-50.683 12.879C3.107 373.027-2.913 366.27 1.39 354.971q26.627-69.911 87.656-131.908c30.862-31.352 41.876-59.308 43.591-106.328 1.715-47.02 40.427-84.661 87.862-84.661 47.435 0 86.149 37.641 87.864 84.661 1.715 47.02 12.729 74.976 43.591 106.328q61.027 61.997 87.656 131.908c4.303 11.299-1.717 18.056-13.436 15.078l-50.683-12.879c-3.348-.85-6.546 1.222-7.137 4.626-11.192 64.401-73.801 118.13-147.855 118.13Zm0-251.676c-1.591.049-3.181-.338-4.393-1.113l-50.912-32.578c-6.324-4.004-7.244-11.629-2.051-17.02 5.194-5.388 29.297-12.27 57.356-12.27 28.061 0 52.162 6.882 57.356 12.27 5.193 5.391 4.275 13.016-2.049 17.02l-50.912 32.578c-1.214.775-2.804 1.162-4.395 1.113Zm-44.627-7.73c-38.185 15.381-67.218 55.636-67.218 102.62 0 61.729 50.116 111.847 111.845 111.847s111.845-50.118 111.845-111.847c0-46.986-29.035-87.243-67.225-102.622-1.601-.647-4.082-.416-5.538.516l-27.383 17.52c-.03.021-.062.043-.083.064a20.762 20.762 0 0 1-5.705 2.473c-1.848.49-3.755.73-5.676.73h-.177l-.062-.011-.054.011h-.175c-1.921 0-3.83-.24-5.686-.73a20.7 20.7 0 0 1-5.697-2.473 3.83 3.83 0 0 0-.094-.064l-27.376-17.516c-1.456-.931-3.937-1.165-5.541-.518Z" />
    </svg>
  );
  const LinuxIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={linuxSvg} {...props} />
  );
  const appleSvg = (color: string) => (
    <svg viewBox="0 0 448 512" width={size} height={size} fill={color}>
      <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" />
    </svg>
  );
  const MacIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={() => appleSvg("#8E8E93")} {...props} />
  );
  const androidSvg = () => (
    <svg viewBox="0 0 448 512" width={size} height={size} fill="#A4C639">
      <path d="M77 179a33 33 0 0 0-25 10 33 33 0 0 0-9 24v143a33 33 0 0 0 10 24 33 33 0 0 0 24 10c9 0 17-3 24-10a33 33 0 0 0 10-24V213c0-9-4-17-10-24a33 33 0 0 0-24-10zM352 51l24-44c1-3 1-5-2-6-3-2-5-1-7 2l-24 43a163 163 0 0 0-133 0L186 3c-2-3-4-4-7-2-2 1-3 3-1 6l23 44c-24 12-43 29-57 51a129 129 0 0 0-21 72h307c0-26-7-50-21-72a146 146 0 0 0-57-51zm-136 63a13 13 0 0 1-10 4 13 13 0 0 1-12-13c0-4 1-7 3-9 3-3 6-4 9-4s7 1 10 4c2 2 3 5 3 9s-1 7-3 9zm140 0a12 12 0 0 1-9 4c-4 0-7-1-9-4a12 12 0 0 1-4-9c0-4 1-7 4-9 2-3 5-4 9-4a12 12 0 0 1 9 4c2 2 3 5 3 9s-1 7-3 9zM124 407c0 10 4 19 11 26s15 10 26 10h24v76c0 9 4 17 10 24s15 10 24 10c10 0 18-3 25-10s10-15 10-24v-76h45v76c0 9 4 17 10 24s15 10 25 10c9 0 17-3 24-10s10-15 10-24v-76h25a35 35 0 0 0 25-10c7-7 11-16 11-26V185H124v222zm352-228a33 33 0 0 0-24 10 33 33 0 0 0-10 24v143a34 34 0 0 0 34 34c10 0 18-3 25-10s10-15 10-24V213c0-9-4-17-10-24a33 33 0 0 0-25-10z" />
    </svg>
  );
  const AndroidIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={androidSvg} {...props} />
  );
  const IosIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={() => appleSvg("#C7C7CC")} {...props} />
  );

  switch (os.toLowerCase()) {
    case 'windows':
      return <WinIcon />;
    case 'linux':
      return <LinuxIcon />;
    case 'macos':
      return <MacIcon />;
    case 'android':
      return <AndroidIcon />;
    case 'ios':
      return <IosIcon />;
    default:
      return;
  }
}

// A better way is to use material-ui icons, but it often fails when adding to umi project.
// yarn add @material-ui/icons
// import { SentimentVeryDissatisfied } from '@material-ui/icons';
export const svgSentimentVeryDissatisfied = () => (
  <svg height="24" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0V0z" fill="none" />
    <circle cx="15.5" cy="9.5" r="1.5" />
    <circle cx="8.5" cy="9.5" r="1.5" />
    <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm0-6c-2.33 0-4.32 1.45-5.12 3.5h1.67c.69-1.19 1.97-2 3.45-2s2.75.81 3.45 2h1.67c-.8-2.05-2.79-3.5-5.12-3.5z" />
  </svg>
);

export const getOidcSettings = GetOidcSettings;

export const getMonospacedFont = () => {
  return 'monospace, "Courier New", Consolas, Monaco, Menlo';
}

export const getTablePaginationConfig = (key: string, initDefaultPageSize?: number): TablePaginationConfig => {
  const key2 = `${key}_table_page_size`;
  const range = [20, 50, 100, 200, 500];
  const getDefaultPageSize = (): number => {
    const v = localStorage.getItem(key2);
    if (v) {
      try {
        const i = parseInt(v, 10);
        if (range.includes(i)) {
          return i;
        }
      } catch (_) { }
    }
    if (initDefaultPageSize) {
      if (range.includes(initDefaultPageSize)) {
        return initDefaultPageSize;
      }
    }
    return 20; // antd default page size
  }

  return {
    defaultPageSize: getDefaultPageSize(),
    pageSizeOptions: range,
    showSizeChanger: true,
    onShowSizeChange: (_: number, size: number) => {
      localStorage.setItem(key2, size.toString());
    }
  };
}
